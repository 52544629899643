import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import VenueModal from "./venueModal";
import { Modal, Button } from "react-bootstrap";
import { Form, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const VenueList = ({
  fetchData,
  currPage,
  totalPage,
  setCurrPage,
  data = [],
  setData,
  setSearchQuery,
  searchQuery,
  cityList
}) => {
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const itemsPerPage = 10;

  const navigate = useNavigate();

  const handleClose = () => setShowVenueModal(false);
  const handleShow = () => setShowVenueModal(true);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const editVenue = (item) => {
    setEditData(item);
    setShowVenueModal(true);
  };

  const handleViewZones = (venueId) => {
    navigate(`/venue/${venueId}/zones`);
  };

  const handleDeleteVenue = async () => {
    try {
      await axiosInstance.delete(`/venue/${deleteId}`);
      setData((prevData) => prevData.filter((venue) => venue._id !== deleteId));
      setDeleteId(null);
      setShowDeleteModal(false);
      swal("Venue Deleted!", "", "success");
    } catch (error) {
      console.error("Error deleting venue:", error);
    }
  };

  const handlePreviousPage = () => {
    if (currPage > 1) setCurrPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (currPage < totalPage) setCurrPage((prev) => prev + 1);
  };
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  }
  const handleSearchSubmit = () =>{
    fetchData(searchQuery);
  }

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>Venue List</h5>
                <div className="d-flex" style={{ maxWidth: "300px" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Venue Name"
                    onChange={handleSearch} 
                    value={searchQuery}
                  />
                  <Button variant="primary" className="ms-2" onClick={handleSearchSubmit}>
                    Search
                  </Button>
                </div>
              </div>

              {<table id="example" className="display w-100 dataTable">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Venue Code</th>
                    <th>Venue Name</th>
                    <th>Capacity</th>
                    <th>Venue Owner Name</th>
                    <th>Image</th>
                    <th>Edit</th>
                    <th>Show Zones</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length ? data.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currPage - 1) * itemsPerPage + index + 1}</td>
                      <td>{item.venue_code}</td>
                      <td>{item.name}</td>
                      <td>{item.capacity}</td>
                      <td>{item.venue_owner_name}</td>
                      <td>
                        {item.imageURL ? (
                          <img
                            src={item.imageURL}
                            alt="Venue"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() => window.open(item.imageURL, "_blank")}
                            onError={(e) => {
                              e.target.onerror = null; // Prevent infinite loop
                              e.target.outerHTML = "NA"; // Replace the image element with "NA"
                            }}
                          />
                        ) : (
                          "NA"
                        )}
                      </td>

                      <td>
                        <i
                          className="fas fa-pen cursor-pointer"
                          onClick={() => editVenue(item)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="bi bi-eye-fill text-primary cursor-pointer"
                          onClick={() => handleViewZones(item.id)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="fas fa-trash text-danger cursor-pointer"
                          onClick={() => handleDeleteModalShow(item.id)}
                        ></i>
                      </td>
                    </tr>
                  )): (
                    <tr>
                      <td colSpan="12" className="text-center">
                        Data is not available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>}

              <div className="pagination-controls d-flex align-items-center justify-content-center gap-2 mt-3">
                <Button
                  variant="primary"
                  disabled={currPage === 1}
                  onClick={handlePreviousPage}
                  className="px-3"
                >
                  Previous
                </Button>
                {Array.from({ length: totalPage }, (_, i) => i + 1)
                  .slice(
                    Math.max(currPage - 3, 0),
                    Math.min(currPage + 2, totalPage)
                  )
                  .map((page) => (
                    <Button
                      key={page}
                      variant={page === currPage ? "dark" : "light"}
                      className="px-3"
                      onClick={() => setCurrPage(page)}
                    >
                      {page}
                    </Button>
                  ))}
                <Button
                  variant="primary"
                  disabled={currPage === totalPage}
                  onClick={handleNextPage}
                  className="px-3"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showVenueModal && (
        <VenueModal
          show={showVenueModal}
          editData={editData}
          handleClose={handleClose}
          fetchData={fetchData}
          cityList={cityList}
        />
      )}

      {showDeleteModal && (
        <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this venue? This action cannot be
            undone.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteModalClose}>
              No
            </Button>
            <Button
              variant="danger"
              onClick={async () => {
                try {
                  await handleDeleteVenue();
                  fetchData();
                  handleDeleteModalClose();
                } catch (error) {
                  console.error("Error during delete operation:", error);
                  swal("Error!", "Failed to delete the venue.", "error");
                }
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default VenueList;
