import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../../../services/AxiosInstance";
import SportsFacilitiesMultiSelect from "./SportsFacilitiesMultiSelect";

const generateInitialValue = (editData = {}) => ({
  venue_code: editData?.venue_code || "",
  venue_name: editData?.name || "",
  latitude: editData?.latitude || "",
  longitude: editData?.longitude || "",
  range: editData?.range || "",
  image: null,
  description: editData?.description || "",
  capacity: editData?.capacity || null,
  sportsFacilities: editData?.sports_facilities || [],
  ownerName: editData?.venue_owner_name || "",
  ownerPhone: editData?.venue_owner_phone || "",
  ownerEmail: editData?.venue_owner_email || "",
});

const VenueModal = ({ show, handleClose, fetchData, editData, cityList}) => {
  const [data, setData] = useState(generateInitialValue(editData));
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(editData?.image || null);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const [selectedCity, setSelectedCity] = useState(editData?.cluster_id || "");

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setErrors((prevErrors) => ({ ...prevErrors, city: "" }));
  };

  useEffect(() => {
    if (editData) {
      setData(generateInitialValue(editData));
      setFileUrl(`${editData?.imageURL}`);
    }
  }, [editData]);

  function isValidMobileNumber(number) {
    const MOBILE_NUMBER_REGEX = /^0?(?:6[3-9]|[7-9]\d)\d{8}$/;
    return MOBILE_NUMBER_REGEX.test(number);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!isValidMobileNumber(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Please enter a valid ${name}.`,
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    if (name === "venue_code") {
      const isValid = /^[a-zA-Z0-9\-_]*$/.test(value); // Updated regex to include alphanumeric
      if (isValid) {
        setData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Venue Code can only contain letters, numbers, '-', and '_'.",
        }));
      }
    } else if (name === "venue_name" || name === "ownerName") {
      const isValid = /^[a-zA-Z\s\-_\/]*$/.test(value);
      if (isValid) {
        setData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `${
            name === "venue_name" ? "Venue" : "Owner"
          } Name can only contain alphabets, spaces, and characters -, _, /.`,
        }));
      }
    } else if (name === "ownerEmail") {
      const isValidEmail = /^[a-zA-Z0-9@.]*$/.test(value);
      if (isValidEmail) {
        setData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Email can only contain alphabets, numbers, '@', and '.'.",
        }));
      }
    } else if (name === "capacity") {
      setData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else {
      setData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleFilePreview = () => {
    if (fileUrl) window.open(fileUrl, "_blank");
  };

  const handleReset = () => {
    setData(generateInitialValue());
    setSelectedFile(null);
    setFileUrl(null);
    setErrors({});
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const handleFileChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file" && files.length > 0) {
      const file = files[0];
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const MAX_FILE_SIZE = 1048576; // 1 MB
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (
        !allowedExtensions.includes(fileExtension) ||
        !allowedTypes.includes(file.type)
      ) {
        setSelectedFile(null);
        setFileUrl(null);
        if (fileInputRef.current) fileInputRef.current.value = null;
        return swal({
          title: "Invalid File Type",
          text: "Only JPG, JPEG, and PNG files are allowed.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
      }

      if (!allowedTypes.includes(file.type)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only JPG, JPEG, and PNG files are allowed.",
        }));
        setSelectedFile(null);
        setFileUrl(null);
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size should not exceed 1 MB.",
        }));
        setSelectedFile(null);
        setFileUrl(null);
        return;
      }

      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else if (type === "file") {
      setSelectedFile(null);
      setFileUrl(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Image is required.",
      }));
      return;
    }

    if (name === "capacity" || name === "range") {
      const isNumeric = /^\d*$/.test(value); // Allow only numbers
      if (isNumeric) {
        setData((prevData) => ({
          ...prevData,
          [name]: value || null,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only numeric values are allowed.",
        }));
      }
      return;
    }

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!data.venue_code) {
      validationErrors.venue_code = "Venue Code is required.";
    } else if (!/^[a-zA-Z0-9\-_]*$/.test(data.venue_code)) {
      validationErrors.venue_code =
        "Venue Code must contain only alphabets, numbers, hyphens (-), and underscores (_).";
    }

    if (!data.venue_name) {
      validationErrors.venue_name = "Venue Name is required.";
    }    

    if (!data.sportsFacilities || !data.sportsFacilities.length) {
      validationErrors.sportsFacilities =
        "At least one sport facility must be selected.";
    }

    if (!/^(-?\d+(\.\d+)?)$/.test(data.latitude)) {
      validationErrors.latitude = "Latitude must be a valid number.";
    }

    if (!/^(-?\d+(\.\d+)?)$/.test(data.longitude)) {
      validationErrors.longitude = "Longitude must be a valid number.";
    }

    if (!data.range || isNaN(data.range) || parseFloat(data.range) <= 0) {
      validationErrors.range = "Range must be a positive number.";
    }

    if (
      data.capacity &&
      (isNaN(data.capacity) || parseInt(data.capacity) <= 0)
    ) {
      validationErrors.capacity = "Capacity must be a positive number.";
    }

    if (!data.description)
      validationErrors.description = "Description is required.";

    if (!data.sportsFacilities || !data.sportsFacilities.length)
      validationErrors.sportsFacilities =
        "At least one sport/facility must be selected.";

    // if (!data.ownerName) {
    //   validationErrors.ownerName = "Owner Name is required.";
    // } else {
    //   const isValidOwnerName = /^[a-zA-Z0-9\s]*$/.test(data.ownerName);
    //   if (!isValidOwnerName) {
    //     validationErrors.ownerName =
    //       "Owner Name can only contain alphabets, numbers, and spaces.";
    //   }
    // }
    // if (
    //   !data.ownerPhone ||
    //   !/^0?(?:6[3-9]|[7-9]\d)\d{8}$/.test(data.ownerPhone)
    // ) {
    //   validationErrors.ownerPhone = "Please enter a valid phone number.";
    // }
    if (
      !data.ownerEmail ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.ownerEmail)
    ) {
      validationErrors.ownerEmail = "Invalid email format.";
    }

    if (!fileUrl && !selectedFile && !editData?.image) {
      validationErrors.image = "Image must have correct format and under 1MB";
    } else if (
      selectedFile &&
      !["image/jpeg", "image/png", "image/jpg"].includes(selectedFile.type)
    ) {
      validationErrors.image = "Only JPG, JPEG, and PNG files are allowed.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      swal(
        "Validation Error",
        "Please fix the highlighted errors in the form.",
        "error"
      );
      return;
    }

    const payload = {
      venue_code: data.venue_code,
      name: data.venue_name.trim(),
      latitude: data.latitude,
      longitude: data.longitude,
      range: data.range,
      description: data.description,
      capacity: data.capacity || null,
      venue_owner_name: data.ownerName.trim(),
      venue_owner_phone: data.ownerPhone,
      venue_owner_email: data.ownerEmail,
      sports_facilities: data.sportsFacilities,
      cluster_id: selectedCity
    };

    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (Array.isArray(payload[key])) {
        payload[key].forEach((item) => formData.append(`${key}[]`, item));
      } else {
        formData.append(key, payload[key]);
      }
    });

    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    try {
      if (editData) {
        const res = await axiosInstance.patch(
          `/venue/${editData.id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (res.data.success) {
          swal("Venue Updated Successfully!", "", "success");
          fetchData();
          handleClose();
          handleReset();
        } else {
          if (
            res.data.duplicates.name === false &&
            res.data.duplicates.venue_code === false
          ) {
            swal(
              "Error!",
              "Both Venue Name and Venue Code are duplicates. Please change the values.",
              "error"
            );
          } else if (res.data.duplicates.name === false) {
            swal(
              "Error!",
              "Venue Name is duplicate. Please change it.",
              "error"
            );
          } else if (res.data.duplicates.venue_code === false) {
            swal(
              "Error!",
              "Venue Code is duplicate. Please change it.",
              "error"
            );
          } else {
            swal(
              "Error!",
              res.data.message || "An unexpected error occurred.",
              "error"
            );
          }
        }
      } else {
        const res = await axiosInstance.post("/venue", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (res.data.success) {
          swal("Venue Created Successfully!", "", "success");
          fetchData();
          handleClose();
          handleReset();
        } else {
          if (
            res.data.duplicates.name === false &&
            res.data.duplicates.venue_code === false
          ) {
            swal(
              "Error!",
              "Both Venue Name and Venue Code are duplicates. Please change the values.",
              "error"
            );
          } else if (res.data.duplicates.name === false) {
            swal(
              "Error!",
              "Venue Name is duplicate. Please change it.",
              "error"
            );
          } else if (res.data.duplicates.venue_code === false) {
            swal(
              "Error!",
              "Venue Code is duplicate. Please change it.",
              "error"
            );
          } else {
            swal(
              "Error!",
              res.data.message || "An unexpected error occurred.",
              "error"
            );
          }
        }
      }
    } catch (error) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";
      swal("Error!", message, "error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">
            {editData ? "Edit Venue" : "Add New Venue"}
          </h4>
          <button type="button" className="btn-close" onClick={handleClose}>
            <span></span>
          </button>
        </div>

        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              {/* Venue Code and Name */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Code <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="venue_code"
                    value={data.venue_code}
                    onChange={handleInputChange}
                    isInvalid={!!errors.venue_code}
                    className={errors.venue_code ? "border-danger" : ""}
                    // placeholder="Enter Venue Code"
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.venue_code}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="venue_name"
                    value={data.venue_name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.venue_name}
                    className={errors.venue_name ? "border-danger" : ""}
                    // placeholder="Enter Venue Name"
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.venue_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* Latitude and Longitude */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Latitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="latitude"
                    value={data.latitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.latitude}
                    className={errors.latitude ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.latitude}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Longitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="longitude"
                    value={data.longitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.longitude}
                    className={errors.longitude ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.longitude}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* Range and Capacity */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Range (in meters) <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="range"
                    value={data.range}
                    onChange={handleInputChange}
                    isInvalid={errors.range}
                    className={errors.range ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.range}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>Capacity</Form.Label>
                  <Form.Control
                    type="number"
                    name="capacity"
                    value={data.capacity}
                    onChange={handleInputChange}
                    isInvalid={!!errors.capacity}
                    className={errors.capacity ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.capacity}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* Image Upload */}
              <div className="row">
                {/* Image Field */}
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Image <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div>
                    <Form.Control
                      type="file"
                      ref={fileInputRef}
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={handleFileChange}
                      isInvalid={!!errors.image}
                      className={errors.image ? "border-danger" : ""}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.image}
                    </Form.Control.Feedback>
                    {selectedFile == null && (
                      <small className="text-muted">
                        <span>
                          Only JPEG, PNG, or JPG formats are allowed (max 1MB).
                        </span>
                      </small>
                    )}
                    {fileUrl && (
                      <Button
                        variant="secondary"
                        className="mt-2"
                        onClick={handleFilePreview}
                      >
                        Preview
                      </Button>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    City <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div>
                    <Form.Select
                      value={selectedCity}
                      onChange={(e) => handleCityChange(e.target.value)}
                      isInvalid={!!errors.city}
                      className={errors.city ? "border-danger" : ""}
                    >
                      <option value="">Select a city</option>
                      {cityList.map(city =>{
                        return <option value={city.city_id}>{city.city_name}</option>
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.city}
                    </Form.Control.Feedback>
                    <small className="text-muted d-block mt-1">
                      Please select your city from the dropdown.
                    </small>
                  </div>
                </Form.Group>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={data.description}
                  onChange={handleInputChange}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Select Sports/Facilities{" "}
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <SportsFacilitiesMultiSelect
                  onChange={(selected) =>
                    setData((prevData) => ({
                      ...prevData,
                      sportsFacilities: selected,
                    }))
                  }
                  selectedValues={data.sportsFacilities}
                />
                {errors.sportsFacilities && (
                  <Alert variant="danger" style={{ marginTop: "10px" }}>
                    {errors.sportsFacilities}
                  </Alert>
                )}
              </Form.Group>

              {/* Venue Owner Details */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Owner Name
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="ownerName"
                    value={data.ownerName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.ownerName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ownerName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Owner Phone Number{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="ownerPhone"
                    value={data.ownerPhone}
                    onChange={handleInputChange}
                    isInvalid={!!errors.ownerPhone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ownerPhone}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>
                  Venue Owner Email Address{" "}
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="ownerEmail"
                  value={data.ownerEmail}
                  onChange={handleInputChange}
                  onBlur={() => {
                    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                      data.ownerEmail
                    );
                    if (!isValidEmail) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        ownerEmail: "Invalid email format.",
                      }));
                    }
                  }}
                  isInvalid={!!errors.ownerEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.ownerEmail}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button variant="secondary" onClick={handleReset}>
            Reset
          </Button>
          <Button type="submit" variant="primary">
            {editData ? "Update Venue" : "Save Venue"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default VenueModal;
