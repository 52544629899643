import React, { useState } from "react";
import PageTitle from "../../../jsx/layouts/PageTitle";
import { Button } from "react-bootstrap";
import PendingList from "./PendingList";

const ApproveUserListing = () => {
  // const [addCard, setAddCard] = useState(false);
  return (
    <>
      <PageTitle
        activeMenu="List"
        motherMenu="Users"
        // Button={() => (
        //   <Button onClick={() => setAddCard(true)}> Share Form</Button>
        // )}
      />
      <PendingList />
    </>
  );
};

export default ApproveUserListing;
