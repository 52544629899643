import React from "react";
import AttendanceQueryModal from "./AttendanceQueryModal";
import AttendanceListingModal from "./AttendanceListingModal";

const AttendanceDataModal = () => {
  return (
    <>
      <AttendanceQueryModal />
      <AttendanceListingModal/>
    </>
  );
};

export default AttendanceDataModal;
