import React, { useState, useEffect, useRef } from "react";
import "./GroupMultiSelectStyle.css";

const GroupMultiSelect = ({
  allVenuesAndZones,
  setSelectedVenueAndZones,
  selectedVenueAndZones,
  setInfinitySelected,
  editData,
}) => {
  let venueData = [];
  venueData = allVenuesAndZones?.map((ent) => {
    return ent.zones.map((zone) => {
      return {
        venue: ent?.venue?.name,
        zone: zone.name,
        id: zone.id,
        venueId: ent?.venue?.id,
      };
    });
  }).flat(); // Flatten the array

  const [selectedZones, setSelectedZones] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (editData) {
      const allSelectedZones = selectedVenueAndZones
        .flatMap((venue) => venue.Zones)
        .map((zoneId) => zoneId);

      setSelectedZones(allSelectedZones);
    }
  }, [selectedVenueAndZones]);

  const handleSelect = (item) => {
    const id = item.id;
    const venueId = item.venueId;

    setSelectedZones((prev) => {
      const newSelectedZones = prev.includes(id)
        ? prev.filter((zoneId) => zoneId !== id)
        : [...prev, id];

      // Group zones by venue ID
      const updatedVenueZones = venueData
        .filter((zone) => newSelectedZones.includes(zone.id))
        .reduce((acc, zone) => {
          const { venueId, id } = zone;
          if (!acc[venueId]) {
            acc[venueId] = [];
          }
          acc[venueId].push(id);
          return acc;
        }, {});

      // Transform grouped data into the desired format
      const formattedVenueZones = Object.keys(updatedVenueZones).map((key) => ({
        venueId: parseInt(key),
        Zones: updatedVenueZones[key],
      }));

      // Update the selectedVenueAndZones state
      setSelectedVenueAndZones(formattedVenueZones);

      // Calculate infinitySelected based on selectedZones length
      setInfinitySelected(newSelectedZones.length === venueData.length);

      return newSelectedZones;
    });
  };

  const handleGroupSelect = (venue) => {
    const venueItems = venueData
      .filter((item) => item.venue === venue)
      .map((item) => item.id);
    const allSelected = venueItems.every((id) => selectedZones.includes(id));

    setSelectedZones((prev) => {
      const newSelectedZones = allSelected
        ? prev.filter((id) => !venueItems.includes(id))
        : [...prev, ...venueItems.filter((id) => !prev.includes(id))];

      // Group zones by venue ID
      const updatedVenueZones = venueData
        .filter((zone) => newSelectedZones.includes(zone.id))
        .reduce((acc, zone) => {
          const { venueId, id } = zone;
          if (!acc[venueId]) {
            acc[venueId] = [];
          }
          acc[venueId].push(id);
          return acc;
        }, {});

      // Transform grouped data into the desired format
      const formattedVenueZones = Object.keys(updatedVenueZones).map((key) => ({
        venueId: parseInt(key),
        Zones: updatedVenueZones[key],
      }));

      // Update the selectedVenueAndZones state
      setSelectedVenueAndZones(formattedVenueZones);

      // Calculate infinitySelected based on selectedZones length
      setInfinitySelected(newSelectedZones.length === venueData.length);

      return newSelectedZones;
    });
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    const allItems = venueData.map((item) => item.id);
    const allSelected = allItems.every((id) => selectedZones.includes(id));

    // Calculate new selected zones
    const newSelectedZones = allSelected ? [] : allItems;

    setSelectedZones(newSelectedZones);

    // Group zones by venue ID
    const updatedVenueZones = venueData
      .filter((zone) => newSelectedZones.includes(zone.id))
      .reduce((acc, zone) => {
        const { venueId, id } = zone;
        if (!acc[venueId]) {
          acc[venueId] = [];
        }
        acc[venueId].push(id);
        return acc;
      }, {});

    // Transform grouped data into the desired format
    const formattedVenueZones = Object.keys(updatedVenueZones).map((key) => ({
      venueId: parseInt(key),
      Zones: updatedVenueZones[key],
    }));

    // Update selectedVenueAndZones state
    setSelectedVenueAndZones(formattedVenueZones);

    // Calculate infinitySelected based on selectedZones length
    setInfinitySelected(newSelectedZones.length === venueData.length);
  };

  const filteredData = venueData.filter(
    (item) =>
      (item.zone &&
        item.zone.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.venue &&
        item.venue.toLowerCase().includes(filterText.toLowerCase()))
  );

  const groupedData = filteredData.reduce((groups, item) => {
    if (!groups[item.venue]) groups[item.venue] = [];
    groups[item.venue].push(item);
    return groups;
  }, {});

  const selectedLabels =
    selectedZones.length === venueData.length
      ? "INFINITY"
      : venueData
          .filter((item) => selectedZones.includes(item.id))
          .map((item) => `${item.zone} (${item.venue})`)
          .join(", ");

  return (
    <div className="custom-dropdown w-100" ref={dropdownRef}>
      <input
        className="dropdown-input"
        type="text"
        readOnly
        value={selectedLabels}
        placeholder="Select Permit Zone(s)"
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      />
      {isDropdownOpen && (
        <div className="dropdown-body">
          <div className="filter-bar">
            <input
              type="text"
              placeholder="Search Zones or Venues"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <div>
              <button className="select-all-btn" onClick={handleSelectAll}>
                {selectedZones.length === venueData.length
                  ? "Deselect All"
                  : "Select All"}
              </button>
            </div>
          </div>
          <div className="dropdown-list">
            {Object.keys(groupedData).map((venue) => (
              <div key={venue} className="dropdown-group">
                <div className="group-header">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={groupedData[venue].every((item) =>
                        selectedZones.includes(item.id)
                      )}
                      onChange={() => handleGroupSelect(venue)}
                    />
                    {venue}
                  </label>
                </div>
                <div className="group-items">
                  {groupedData[venue].map((item) => (
                    <label
                      key={item.id}
                      className="dropdown-item checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedZones.includes(item.id)}
                        onChange={() => handleSelect(item)}
                      />
                      {item.zone}
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupMultiSelect;