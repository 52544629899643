import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import Pagination from "../permitUsers/Pagination";
import swal from "sweetalert";
import { tokenExtractor } from "../../../utils/helper/tokenExtractor";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

const PermitUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const authToken = localStorage.getItem("access_token");
  // const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10);
  //const [selectedOption, setSelectedOption] = useState(3);
  const [selectedOption, setSelectedOption] = useState({
    value: 3,
    label: "All",
  });
  const [uploadLetterError, setUploadLetterError] = useState(null);
  const uploadLetterInputRef = useRef(null);
  const [allCitiesAndVenues, setAllCitiesAndVenues] = useState([]);
  const [selectedVenueIds, setSelectedVenueIds] = useState([]);
  // const { userTypes } = useContext(PermissionContext);
  const [disapproveModalOpen, setDisapproveModalOpen] = useState(false);
  const [disapproveComment, setDisapproveComment] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [itemsPerPage] = useState(10);
  const [editedUserType, setEditedUserType] = useState({
    value: "",
    registration_data_id: "",
  });
  const [userTypeModalOpen, setUserTypeModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [editedParentType, setEditedParentType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState(
    searchParams.get("selectedUserType") || ""
  );
  const [filteredData, setFilteredData] = useState([]);
  // const [selectedUserType, setSelectedUserType] = useState("");
  const [userData, setUserData] = useState([]); // Define setUserData here

  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      setCurrentPage(1); 
      fetchUsers(); 
      return;
    }
  
    try {
      setLoading(true);
      const url = `/user/registrationform?page=${currentPage}&limit=${limit}&name=${searchTerm}`;
  
      const response = await axiosInstance.get(url);
  
      const filteredData = response?.data?.data || []; 
      const totalPages = response?.data?.totalPages || 0;
  
      if (filteredData.length > 0) {
        setFilteredUsers(filteredData); 
      } else {
        setFilteredUsers([]); 
        swal("Info", "No users found with the given search term.", "info");
      }
  
      setTotalPages(totalPages);
    } catch (error) {
      const message =
        error.response?.data?.message || "An error occurred while searching.";
      swal("Error", message, "error");
      setFilteredUsers([]); 
      setTotalPages(0); 
    } finally {
      setLoading(false); 
    }
  };
  

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const [registrationID, setregistrationID] = useState("");

  function windowReloader() {
    // window.location.reload();
  }
  const [currentPage, setCurrentPage] = React.useState(
    parseInt(searchParams.get("page") || "1", 10)
  );

  const handleExport = async () => {
    if (!selectedUserType) {
      swal("Error", "Please select a user type before exporting.", "error");
      return;
    }

    try {
      const response = await axiosInstance.get("/user/registrationform", {
        params: {
          sub_category: selectedUserType,
          download: true,
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "filtered_users_list.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      swal("Success", "File downloaded successfully!", "success");
    } catch (error) {
      const message =
        error.response?.data?.message || "An error occurred during export.";
      swal("Error", message, "error");
    }
  };

  const fetchUserData = async (userType) => {
    try {
      console.log(127)
      setLoading(true);
      const url = `/user/registrationform?page=${currentPage}&limit=${limit}&`;
      if(selectedUserType){
        url=+`sub_category=${selectedUserType}`
      }

      const response = await axiosInstance.get(url);

      const data = response.data.data || []; // Get user data
      const totalPages = response.data.totalPages || 0; // Get total pages

      if (data.length === 0) {
        setUserData([]); // Clear user data if empty response
        setFilteredUsers([]); // Clear filtered users
      } else {
        setUserData(data); // Set fetched user data
        setFilteredUsers(data); // Set filtered users
      }

      setTotalPages(totalPages); // Update total pages for pagination
    } catch (error) {
      // swal(
      //   "Error",
      //   "Failed to fetch user data for the selected type.",
      //   "error"
      // );
      setUserData([]); // Clear user data in case of error
      setFilteredUsers([]);
      setTotalPages(0); // Reset total pages
    } finally {
      setLoading(false); // Stop the loading indicator
    }
  };

  useEffect(() => {
    if (!authToken) {
      setLoading(false);
      return;
    }
    fetchUsers();
    fetchCitiesAndVenues();
  }, [authToken, currentPage, limit, selectedOption,selectedUserType]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const fetchCitiesAndVenues = async () => {
    try {
      const response = await axiosInstance.get("/venue/cities");
      setAllCitiesAndVenues(response.data || []);
    } catch (error) {
      console.error("Error fetching cities and venues:", error);
    }
  };

  useEffect(() => {
    const fetchUserTypes = async () => {
      try {
        const response = await axiosInstance.get(`/user-sub-type`);
        const data = response?.data?.data || [];
        setUserTypes(data);
      } catch (error) {
        const message =
          error.response?.data?.message || "Failed to fetch user types.";
        swal("Error", message, "error");
      }
    };

    fetchUserTypes();
  }, []);

  const fetchUsers = async () => {
    try {
      console.log(198)
      console.log("selectedUserType",selectedUserType)

      
      setLoading(true);
      const url = `/user/registrationform?page=${currentPage}&reg_status=${
        selectedOption?.value
      }&limit=${limit}${
        selectedUserType ? `&sub_category=${selectedUserType}` : ""
      }`;
      console.log(url)
console.log(selectedUserType)
      const response = await axiosInstance.get(url);

      if (Array.isArray(response?.data?.data)) {
        setUsers(response?.data?.data);
        setFilteredUsers([]);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      } else {
        setUsers([]);
        setFilteredUsers([]);
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred while fetching users.";
      swal("Error", message, "error");
    } finally {
      setLoading(false); // Stop the loading indicator
    }
  };

  function isAdmin() {
    let decryptedToken = tokenExtractor().user_level == 3;
    return decryptedToken ? true : false;
  }

  const options = [
    { value: 3, label: "All" },
    { value: 0, label: isAdmin() ? "Ready For Printing" : "Approve" },
    { value: 2, label: isAdmin() ? "Sent For Printing" : "Sent For Printing" },
    {
      value: 1,
      label: isAdmin() ? "" : "Sent for Accredation",
    },
  ];

  const fetchFilteredUsers = async (categoryId) => {
    try {
      console.log(244)
      setLoading(true);
      const url = `/user/registrationform?page=${currentPage}&limit=${limit}&reg_status=${selectedOption?.value}&category_id=${categoryId}&`;
      if (selectedUserType) {
        url += `sub_category=${selectedUserType}`;
      }
      const response = await axiosInstance.get(url);

      const data = response?.data?.data || [];
      const totalPages = response?.data?.totalPages || 0;

      if (data.length === 0) {
        setFilteredData([]);
      } else {
        setFilteredData(data);
      }

      setTotalPages(totalPages);
    } catch (error) {
      const message =
        error.response?.data?.message || "Failed to fetch filtered users.";
      swal("Error", message, "error");
      setFilteredData([]);
      setTotalPages(0);
    } finally {
      setLoading(false);
    }
  };

  const handleDisapprove = async () => {
    if (!currentUser) {
      swal("Error", "No user selected for disapproval.", "error");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/user/disapprove/${currentUser.registration_data_id}`,
        { comment: disapproveComment }
      );

      if (response.status === 200) {
        const updatedUsers = users.map((user) => {
          if (user.registration_data_id === currentUser.registration_data_id) {
            return {
              ...user,
              reg_status: "Disapproved",
              disapprove_comment: disapproveComment,
              registration_status: 1,
            };
          }
          return user;
        });
        setUsers(updatedUsers);
        swal("Success", "User disapproved successfully!", "success");
        setDisapproveModalOpen(false); // Close the modal
        setDisapproveComment(""); // Reset the comment input
      } else {
        swal("Error", "Disapproval failed. Please try again.", "error");
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred while disapproving the user.";
      swal("Error", message, "error");
    } finally {
      windowReloader();
    }
  };

  const handleDisapproveClick = (item) => {
    setCurrentUser(item);
    setDisapproveModalOpen(true);
  };

  const handleChange = (selected) => {
    setSelectedOption(selected);
    setCurrentPage(1);
  };

  const captureUser = async (item) => {
    try {
      const response = await axiosInstance.post(
        `user/registrationform/approve/${item.registration_data_id}`
      );
      if (response.status === 200) {
        swal("Success", "User Approved successfully", "success");
        fetchUsers();
      } else {
        swal("Error", "Failed to approve user.", "error");
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred while approving the user.";
      swal("Error", message, "error");
    }
  };

  const approveBySubAdmin = async (item) => {
    try {
      const response = await axiosInstance.put(
        `user/approved-by-subAdmin/${item.registration_data_id}`
      );
      if (response.status === 200) {
        swal("Success", "User Sent to Admin for Approval", "success");
        fetchUsers();
      } else {
        swal("Error", "Failed to approve user.", "error");
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred while approving the user.";
      swal("Error", message, "error");
    } finally {
      windowReloader();
    }
  };

  const handleSaveEdit = async () => {
    try {
      const updatedUser = {
        ...selectedUser,
        organisation: editedUserType.value,
        category_name: editedUserType.value,
      };

      let res = await axiosInstance.put(
        `/user/registrationform/${registrationID}`,
        updatedUser
      );

      // setUsers((prevUsers) =>
      //   prevUsers.map((user) =>
      //     user.registration_data_id === selectedUser.registration_data_id
      //       ? {
      //           ...user,
      //           organisation: editedUserType,
      //           category_name: editedParentType,
      //         }
      //       : user
      //   )
      // );

      if (res.data.success) {
        swal("Success", res.data.message, "success");
        // fetchUsers();
      }
    } catch (error) {
      console.log(error.message);
      swal("Error", "Failed to update user details.", "error");
    } finally {
      setEditModalOpen(false);

      // windowReloader();
    }
  };

  useEffect(() => {
    let obj = { page: currentPage };
    if (selectedUserType) {
      obj["selectedUserType"] = selectedUserType;
    }
    setSearchParams(obj);
  }, [currentPage, setSearchParams, selectedUserType]);

  useEffect(() => {
    const fetchUserTypes = async () => {
      try {
        const response = await axiosInstance.get("/user-sub-type");
        setUserTypes(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching User Types:", error);
        if (error.response) {
          swal(
            "Error",
            `Failed to fetch user types: ${error.response.status} - ${
              error.response.data?.message || error.response.statusText
            }`,
            "error"
          );
        } else if (error.request) {
          swal(
            "Error",
            "No response received from the server. Please check your connection.",
            "error"
          );
        } else {
          swal("Error", `Unexpected error occurred: ${error.message}`, "error");
        }
      }
    };

    if (editModalOpen) {
      fetchUserTypes();
    }
  }, [editModalOpen]);

  const handlEditUserType = async (e) => {
    setEditedUserType({ value: e.target.value });
  };
  const getLabel =  (item) =>{
    let label = "";
    if(item.reg_status){
      label = item?.reg_status?.toUpperCase();
    }
    else if (item.registration_status == "0" && item.user_created_by && isAdmin()){
      label = "CAPTURE";
    }
    else if(item.registration_status == "0" && item.user_created_by && !isAdmin()){
      label = "SEND TO ACCRED";
    }
    return label;
  }
  // const disapproveUser = async (user) => {
  //   const { id } = user;
  //   return axiosInstance.post(`/user/disapprove/${id}`, { registration_status: 2 });
  // };
  useEffect(() => {
    setSelectedOption({ value: 3, label: "All" });
  }, []);

  //sub_category_id
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Users List</h5>
            <div className="d-flex align-items-center">
              {/* Dropdown */}
              <select
                className="form-select form-select-sm"
                value={selectedUserType}
                onChange={async (e) => {
                  const userType = e.target.value;
                  setSelectedUserType(userType);
                  setCurrentPage(1);

                  if (userType) {
                    await fetchUserData(userType);
                  } else {
                    setUserData([]);
                    setFilteredUsers([]);
                  }
                }}
              >
                <option value="">Select User Type</option>
                {userTypes.map((userType) => (
                  <option key={userType.id} value={userType.sub_category_id}>
                    {userType?.sub_category_name_view}
                  </option>
                ))}
              </select>

              {/* Export Button */}
              <button
                className="btn btn-primary btn-sm ms-3"
                onClick={handleExport}
              >
                Export Excel
              </button>
            </div>
          </div>

          <div className="pb-2 row justify-content-center">
            <div className="col-md-3">
              <select
                className="form-control form-control-sm"
                value={limit}
                onChange={handleLimitChange}
              >
                {[10, 20, 30, 40, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-9 d-flex justify-content-end gap-2">
              <div className="col-md-3">
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleChange}
                  classNamePrefix="react-select"
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search by First Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="w-100 table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Aadhar Number</th>
                    <th>Phone Number</th>
                    <th>User Photo</th>
                    <th>Press ID Photo</th>
                    <th>User Type</th>
                    <th>Responsible Organization</th>
                    <th>Created By</th>
                    <th>Uploaded Letter</th>
                    <th>Approve</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((item, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * limit + index + 1}</td>
                        <td>{item.first_name || "N/A"}</td>
                        <td>{item.middle_name || "N/A"}</td>
                        <td>{item.last_name || "N/A"}</td>
                        <td>{item.email_id || "N/A"}</td>
                        <td>{item.id_proof_number || "N/A"}</td>
                        <td>{item.mobile_number || "N/A"}</td>
                        <td>
                          {item.player_image_path ? (
                            <img
                              onClick={() =>
                                window.open(item.player_image_path, "_blank")
                              }
                              src={item.player_image_path}
                              alt="User"
                              style={{
                                maxWidth: "100px",
                                borderRadius: "4px",
                              }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                        <td>
                          {item.office_id_image ? (
                            <img
                              onClick={() =>
                                window.open(item.office_id_image, "_blank")
                              }
                              src={item.office_id_image}
                              alt="Press ID"
                              style={{
                                maxWidth: "100px",
                                borderRadius: "4px",
                              }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                        <td>{item.category_name || "N/A"}</td>
                        <td>{item.organisation || "N/A"}</td>
                        <td>{item?.created_by?.fullname || "Manual"}</td>
                        <td>
                          {item.letter_of_authorization ? (
                            <a
                              href={item.letter_of_authorization}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Document
                            </a>
                          ) : (
                            "No Document"
                          )}
                        </td>
                        <td>
                          <div className="d-flex" style={{ gap: "10px" }}>
                            {/* Approve Button */}
                            {item.registration_status !== 2 && (
                              <button
                                className="btn btn-sm btn-success"
                                onClick={async () => {
                                  try {
                                    const response = await (isAdmin()
                                      ? captureUser(item)
                                      : approveBySubAdmin(item));

                                    if (response.status === 200) {
                                      const updatedUsers = [...userData];
                                      const index = updatedUsers.findIndex(
                                        (user) =>
                                          user.registration_data_id ===
                                          item.registration_data_id
                                      );
                                      if (index !== -1) {
                                        updatedUsers[index] = {
                                          ...updatedUsers[index],
                                          registration_status: 2,
                                          reg_status: "Sent",
                                        };
                                      }
                                      setUserData(updatedUsers);
                                      setFilteredUsers(updatedUsers);
                                    } else {
                                      swal(
                                        "Error",
                                        "Failed to approve the user.",
                                        "error"
                                      );
                                    }
                                  } catch (error) {
                                    // swal(
                                    //   "Error",
                                    //   "Something went wrong while approving the user.",
                                    //   "error"
                                    // );
                                  }
                                }}
                                disabled={
                                  item?.is_captured === "1" ||
                                  item?.reg_status === "Sent"
                                }
                              >
                                {item?.reg_status?.toUpperCase() || "Approve"}
                              </button>
                            )}

                            {(() => {
                              if (
                                (isAdmin() &&
                                  item.registration_status === "2") ||
                                (!isAdmin() &&
                                  (item.registration_status === "1" ||
                                    item.registration_status === "2"))
                              ) {
                                return null;
                              }

                              return (
                                <>
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={async () => {
                                      try {
                                        const response =
                                          await handleDisapproveClick(item);

                                        if (response.status === 200) {
                                          const updatedUsers = [...userData];
                                          const index = updatedUsers.findIndex(
                                            (user) =>
                                              user.registration_data_id ===
                                              item.registration_data_id
                                          );
                                          if (index !== -1) {
                                            updatedUsers[index] = {
                                              ...updatedUsers[index],
                                              registration_status: 0,
                                              reg_status: "Disapproved",
                                            };
                                          }
                                          setUserData(updatedUsers);
                                          setFilteredUsers(updatedUsers);
                                        } else {
                                          swal(
                                            "Error",
                                            "Failed to disapprove the user.",
                                            "error"
                                          );
                                        }
                                      } catch (error) {
                                        // swal(
                                        //   "Error",
                                        //   "Something went wrong while disapproving the user.",
                                        //   "error"
                                        // ); \
                                      }
                                    }}
                                    disabled={item?.is_captured === "1"}
                                  >
                                    Disapprove
                                  </button>

                                  <button
                                    onClick={() => {
                                      setregistrationID(
                                        item.registration_data_id
                                      );
                                      setEditModalOpen(true);
                                    }}
                                    className="btn btn-sm btn-primary"
                                  >
                                    Edit
                                  </button>
                                </>
                              );
                            })()}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No users found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
        </div>
      </div>
      {/* Select User Type Modal */}
      <Modal
        show={userTypeModalOpen}
        onHide={() => setUserTypeModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list-group">
            {userTypes.map((type) => (
              <button
                key={type.category_id}
                className="list-group-item list-group-item-action"
                onClick={() => {
                  setSelectedUserType(type.category_id);
                  fetchFilteredUsers(type.category_id);
                  setUserTypeModalOpen(false);
                }}
              >
                {type.category_name_view}
              </button>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setUserTypeModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={editModalOpen} onHide={() => setEditModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="editUserType" className="form-label">
              <strong>User Type</strong>
            </label>
            <select
              id="editUserType"
              value={editedUserType.value}
              onChange={(e) => handlEditUserType(e)}
              className="form-control"
            >
              <option value="">Select a user type</option>
              {userTypes.map((userType) => (
                <option key={userType.id} value={userType.sub_category_id}>
                  {userType?.sub_category_name_view}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalOpen(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveEdit}
            disabled={
              users.registration_status === "2" || // Disable button based on registration_status
              (!isAdmin() &&
                (users?.registration_status === "1" ||
                  users?.registration_status === "2"))
            }
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={disapproveModalOpen}
        onHide={() => setDisapproveModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Disapprove User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Reason for Disapproval</label>
            <textarea
              value={disapproveComment}
              onChange={(e) => setDisapproveComment(e.target.value)}
              className="form-control"
              rows="4"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDisapproveModalOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="danger"
            onClick={handleDisapprove}
            disabled={
              users?.registration_status === "2" || // Disable button based on registration_status
              (!isAdmin() &&
                (users?.registration_status === "1" ||
                  users?.registration_status === "2"))
            }
          >
            Disapprove
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PermitUsers;
