// Auth Url
export const SUPER_ADMIN_LOGIN_URL = "/api/sadmin/login/sadmin";

// CLIENT URL
export const GET_CLIENT_URL = "/api/sadmin/clients";
export const GET_CLIENT_BY_ID_URL = "/api/sadmin/client/";
export const CREATE_CLIENT_URL = "/api/sadmin/addClient";
export const EDIT_CLIENT_URL = "/api/sadmin/editClient/";
export const UPLOAD_CLIENT_LOGO = "/api/sadmin/upload-logo";

export const GET_CATEGORY_URL = "/api/sadmin/getUserCategiryMain";

//  CATEGORIES TYPE
export const GET_CATEGORY_TYPE_URL = "/api/sadmin/findUserCategoryType";
export const CREATE_CATEGORY_TYPE_URL = "/api/sadmin/createUserCategoryType";
export const GET_CATEGORY_TYPE_BY_ID_URL = "/api/sadmin/findUserCategoryTypeById/";
export const UPDATE_CATEGORY_TYPE_URL = "/api/sadmin/updateUserCategoryType/";

// SUB CATEGORIES URL
export const GET_SUB_CATEGORY_URL = "/api/sadmin/findUserCategorySubType";
export const CREATE_SUB_CATEGORY_URL = "/api/sadmin/createUserCategorySubType";
export const GET_SUB_CATEGORY_BY_ID_URL = "/api/sadmin/findUserCategorySubTypeById/";
export const UPDATE_SUB_CATEGORY_URL = "/api/sadmin/updateUserCategorySubType/";
