import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../../../services/AxiosInstance";
import { useParams } from "react-router-dom";

const initialZoneData = {
  zone_code: "",
  name: "",
  latitude: "",
  longitude: "",
  capacity: "" || null,
  range: "",
  description: "",
};

const ZoneModal = ({ show, handleClose, editData, fetchZones }) => {
  const [zoneData, setZoneData] = useState(initialZoneData);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const [isPreviewDisabled, setIsPreviewDisabled] = useState(true);

  useEffect(() => {
    if (editData) {
      setZoneData({
        zone_code: editData.zone_code || "",
        name: editData.name || "",
        latitude: editData.latitude || "",
        longitude: editData.longitude || "",
        capacity: editData.capacity || null,
        range: editData.range || "",
        description: editData.description || "",
      });
      setFileUrl(editData.imageURL || null);
    } else {
      setZoneData(initialZoneData);
      setFileUrl(null);
    }
    setErrors({});
  }, [editData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "zone_code") {
      const isValidZoneCode = /^[a-zA-Z0-9-_]*$/.test(value);
      if (isValidZoneCode) {
        setZoneData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Zone Code can only contain alphabets, numbers, '-', and '_'.",
        }));
      }
      const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
          const file = e.target.files[0];
          const maxFileSize = 1 * 1024 * 1024; // 1 MB
          const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

          if (!allowedFileTypes.includes(file.type)) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              image: "Only JPG, JPEG, and PNG file formats are allowed.",
            }));
            setSelectedFile(null);
            setFileUrl(null);
            setIsPreviewDisabled(true);
          } else if (file.size > maxFileSize) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              image: "File size should not exceed 1 MB.",
            }));
            setSelectedFile(null);
            setFileUrl(null);
            setIsPreviewDisabled(true);
          } else {
            setSelectedFile(file);
            setFileUrl(URL.createObjectURL(file));
            setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
            setIsPreviewDisabled(false);
          }
        } else {
          setSelectedFile(null);
          setFileUrl(null);
          setErrors((prevErrors) => ({
            ...prevErrors,
            image: "Image is required.",
          }));
          setIsPreviewDisabled(true);
        }
      };
    } else {
      setZoneData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const maxFileSize = 1 * 1024 * 1024; // 1 MB
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!allowedFileTypes.includes(file.type)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: "Only JPG, JPEG, and PNG file formats are allowed.",
        }));
        setSelectedFile(null);
        setFileUrl(null);
        setIsPreviewDisabled(true); // Disable button
      } else if (file.size > maxFileSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: "File size should not exceed 1 MB.",
        }));
        setSelectedFile(null);
        setFileUrl(null);
        setIsPreviewDisabled(true); // Disable button
      } else {
        setSelectedFile(file);
        setFileUrl(URL.createObjectURL(file));
        setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
        setIsPreviewDisabled(false); // Enable button
      }
    } else {
      setSelectedFile(null);
      setFileUrl(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Image is required.",
      }));
      setIsPreviewDisabled(true); // Disable button
    }
  };

  const handleFilePreview = () => {
    console.log(fileUrl);
    if (fileUrl) window.open(fileUrl, "_blank");
  };

  const handleReset = () => {
    setZoneData(initialZoneData);
    setSelectedFile(null);
    setFileUrl(null);
    setErrors({});
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!zoneData.zone_code) {
      validationErrors.zone_code = "Zone Code is required.";
    } else if (!/^[a-zA-Z0-9-_]*$/.test(zoneData.zone_code)) {
      validationErrors.zone_code =
        "Zone Code can only contain alphabets, numbers, '-', and '_'.";
    }
    if (!zoneData.name) validationErrors.name = "Zone Name is required.";
    if (!/^(-?[0-9]{1,3}(?:\.\d{1,6})?)$/.test(zoneData.latitude)) {
      validationErrors.latitude =
        "Latitude must be a valid number between -90 and 90.";
    }
    if (!/^(-?[0-9]{1,3}(?:\.\d{1,6})?)$/.test(zoneData.longitude)) {
      validationErrors.longitude =
        "Longitude must be a valid number between -180 and 180.";
    }
    if (
      !zoneData.range ||
      isNaN(zoneData.range) ||
      parseInt(zoneData.range) <= 0
    ) {
      validationErrors.range = "Range must be a positive number.";
    }
    if (!zoneData.description)
      validationErrors.description = "Description is required.";
    // if (!selectedFile && !editData)
    //   validationErrors.image = "Image is required.";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      swal(
        "Validation Error",
        "Please fix the highlighted errors in the form.",
        "error"
      );
      return;
    }

    if (selectedFile) {
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxFileSize = 1 * 1024 * 1024; // 1 MB

      if (!allowedFileTypes.includes(selectedFile.type)) {
        swal(
          "Validation Error",
          "Only JPG, JPEG, and PNG file formats are allowed.",
          "error"
        );
        return;
      }

      if (selectedFile.size > maxFileSize) {
        swal("Validation Error", "Image size should not exceed 1 MB.", "error");
        return;
      }
    }

    const payload = { ...zoneData, venue_id: id };

    if (!zoneData.capacity) {
      payload.capacity = null;
    }

    try {
      const formData = new FormData();

      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      Object.entries(payload).forEach(([key, value]) =>
        formData.append(key, value)
      );

      let res;

      if (editData) {
        res = await axiosInstance.put(`/zones/${editData.id}`, formData);
        if (res.data.success) {
          swal(
            "Zone Updated!",
            "The zone has been updated successfully.",
            "success"
          );
        } else {
          swal(
            "Error!",
            "Error Updating Zone, Zone name/code duplicacy may have occurred.",
            "error"
          );
        }
      } else {
        res = await axiosInstance.post("/zones", formData);
        if (res.data.success) {
          swal(
            "Zone Created!",
            "The new zone has been created successfully.",
            "success"
          );
        } else {
          swal(
            "Error!",
            "Error Creating Zone, Zone name/code duplicacy may have occurred.",
            "error"
          );
        }
      }

      fetchZones();
      handleClose();
    } catch (error) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";

      // Handle backend validation errors specifically for unacceptable files
      if (error.response?.data?.errors?.image) {
        swal("Validation Error", error.response.data.errors.image[0], "error");
      } else {
        swal("Error occurred!", message, "error");
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">
            {editData ? "Edit Zone" : "Add Zone"}
          </h4>
          <button type="button" className="btn-close" onClick={handleClose}>
            <span></span>
          </button>
        </div>
        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              {/* Zone Code and Zone Name */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Zone Code <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zone_code"
                    value={zoneData.zone_code}
                    onChange={handleInputChange}
                    isInvalid={!!errors.zone_code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zone_code}
                    an error
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Zone Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={zoneData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              {/* Latitude and Longitude */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Latitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="latitude"
                    value={zoneData.latitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.latitude}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.latitude}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Longitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="longitude"
                    value={zoneData.longitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.longitude}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.longitude}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              {/* Capacity and Range */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>Capacity</Form.Label>
                  <Form.Control
                    type="number"
                    name="capacity"
                    value={zoneData.capacity}
                    onChange={handleInputChange}
                    min="0" 
                    isInvalid={!!errors.capacity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.capacity}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Range (in meters) <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="range"
                    value={zoneData.range}
                    onChange={handleInputChange}
                    min="0" 
                    isInvalid={!!errors.range}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.range}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* Description */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={zoneData.description}
                  onChange={handleInputChange}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              {/* File Input */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Image
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileChange}
                    isInvalid={!!errors.image}
                  />
                  <Button
                    variant="primary"
                    className="ms-2"
                    disabled={isPreviewDisabled}
                    onClick={handleFilePreview}
                  >
                    Preview
                  </Button>
                </div>
                {errors.image && (
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block mt-1"
                  >
                    {errors.image}
                  </Form.Control.Feedback>
                )}
                <small className="text-muted mt-2 d-block">
                  Accepted file formats: JPG, JPEG, PNG
                </small>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button variant="secondary" onClick={handleReset}>
            Reset
          </Button>
          <Button type="submit" variant="primary">
            {editData ? "Update Zone" : "Save Zone"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ZoneModal;
