import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";
import { tokenExtractor } from "../../../utils/helper/tokenExtractor";

const ShareForm = ({ addCard, setAddCard }) => {
  const [subtypes, setSubTypes] = useState([]);
  const [form, setForm] = useState({ email: "", name: "", userType: "" });
  const [isFormValid, setIsFormValid] = useState(false);
  const [formLink, setFormLink] = useState("");
  const [isMedia, setIsMedia] = useState(false);

  const getUserTypes = async () => {
    try {
      const response = await axiosInstance.get("/user-sub-type");
      setSubTypes(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching User Types:", error);
    }
  };

  const user_id = () => {
    return tokenExtractor()?.id || null;
  };

  const generateFormLink = () => {
    const payload = {
      userType: form.userType,
      shared_by: user_id(),
    };

    const token = window.btoa(JSON.stringify(payload));
    const baseUrl =
      process.env.REACT_APP_FRONTEND_URL || "http://localhost:3000";
    const link = `${baseUrl}/manual-registration?by=${token}`;
    setFormLink(link);
  };

  useEffect(() => {
    setIsFormValid(
      form.name.trim() !== "" &&
        form.email.trim() !== "" &&
        form.userType !== ""
    );
  }, [form]);

  useEffect(() => {
    getUserTypes();

    const query = new URLSearchParams(window.location.search);
    const mediaForm = query.get("type");
    if (mediaForm && mediaForm === "media") {
      setIsMedia(true); 
    }
  }, []);

  const shareOnEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/email/send-email", {
        to: {
          name: form.name,
          email: form.email,
          shared_by: user_id(),
        },
        // userType: form.userType,
      });

      if (response.status === 200) {
        swal("Success", "Form Shared Successfully", "success");
        setAddCard(false);
      }
    } catch (error) {
      console.error("Error sharing form:", error);
      swal("Error", "Failed to share the form. Try again.", "error");
    }
  };

  const copyToClipboard = () => {
    if (formLink) {
      navigator.clipboard.writeText(formLink).then(() => {
        swal("Copied!", "Form link copied to clipboard.", "success");
      });
    } else {
      swal("Error", "Form link is not available.", "error");
    }
  };

  return (
    <Modal
      className="modal fade"
      show={addCard}
      onHide={() => setAddCard(false)}
    >
      <div className="" role="document">
        <div className="">
          <form onSubmit={shareOnEmail}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Share Manual Form</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddCard(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="text-black font-w500">
                        Enter User Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        name="text"
                        type="text"
                        placeholder="Enter Name"
                        value={form.name}
                        onChange={(e) => {
                          setForm((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label className="text-black font-w500">
                        Enter User Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        name="email"
                        type="email"
                        placeholder="Enter Email"
                        value={form.email}
                        onChange={(e) => {
                          setForm((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <div className="col-lg-6 mt-3">
                      <label className="text-black font-w500">
                        Select User Type
                      </label>
                      <select
                        className="form-control"
                        value={form.userType}
                        onChange={(e) => {
                          setForm((prev) => ({
                            ...prev,
                            userType: e.target.value,
                          }));
                          generateFormLink();
                        }}
                      >
                        <option value="">Select Type</option>
                        {subtypes
                          .filter((type) =>
                            isMedia ? type.sub_category_id === 61 || type.sub_category_id === 62 : true
                          )
                          .map((type) => (
                            <option key={type.sub_category_id} value={type.sub_category_id}>
                              {type.sub_category_name_view.trim()}
                            </option>
                          ))}
                      </select>
                    </div>

                    {form.userType && formLink && (
                      <div className="col-lg-12 mt-3">
                        <label className="text-black font-w500">
                          Copy Link
                        </label>
                        <div className="d-flex align-items-center">
                          <input
                            className="form-control me-2"
                            type="text"
                            value={formLink}
                            readOnly
                          />
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={copyToClipboard}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Share
              </button>
              <button
                type="button"
                onClick={() => setAddCard(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ShareForm;
