import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import axiosInstance from "../../../../src/services/AxiosInstance";
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import { PermissionContext } from "./PermissionContext";
import Pagination from "../permitUsers/Pagination";

const PermissionList = () => {
  const {
    userType: userTypes,
    sport: sports,
    permissionList,
    totalPages,
    currentPage,
    setCurrentPage,
    limit,
    setLimit,
  } = useContext(PermissionContext);

  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // State to store search query
  const [filteredList, setFilteredList] = useState(permissionList?.data || []); // Filtered list of permissions
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleSearch = () => {
    const filtered = permissionList?.data?.filter((item) =>
      item.user_category_sub_type.sub_category_name_view
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
    setFilteredList(filtered);
  };

  const handleEditPermission = (item) => {
    setEditData(item);
    setShowEditModal(true);
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Permission List</h5>
          </div>

          <div className="pb-2 row">
            <div className="col-md-1">
              <select
                className="form-control form-control-sm"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>

          <div className="w-100 table-responsive">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>User Type</th>
                    <th>Gender</th>
                    <th>Sport</th>
                    <th>Venue & Zones</th>
                    <th>Colour Code</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {permissionList?.data?.length > 0 ? (
                    permissionList?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * limit + index + 1}</td>
                        <td>
                          {item?.user_category_sub_type
                            ?.sub_category_name_view || "N/A"}
                        </td>
                        <td>
                          {item.gender === 1
                            ? "Male"
                            : item.gender === 2
                            ? "Female"
                            : item.gender === 3
                            ? "Both"
                            : "N/A"}
                        </td>
                        <td>
                          {item?.ref_sports_name?.sport_name
                            ? `${item.ref_sports_name.sport_name} ${
                                item?.sub_sport_category_id?.category_name
                                  ? `(${item.sub_sport_category_id.category_name})`
                                  : ""
                              }`
                            : "N/A"}
                        </td>

                        <td>
                          {item.venues?.length > 0 ? (
                            item.isInfinite ? (
                              <span
                                style={{
                                  color: "#922b21",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                INFINITE
                              </span>
                            ) : (
                              item.venues.map((venue) => (
                                <div
                                  key={venue.venue_id}
                                  style={{
                                    padding: "8px 12px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      color: "#333",
                                      display: "inline-block",
                                      marginRight: "10px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {venue.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      flexWrap: "wrap",
                                      gap: "8px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {venue.zones?.length > 0 ? (
                                      venue.zones.map((zone) => (
                                        <span
                                          key={zone.id}
                                          style={{
                                            backgroundColor: "#f0f0f0",
                                            borderRadius: "4px",
                                            padding: "4px 8px",
                                            fontSize: "10px",
                                            color: "#333",
                                            boxShadow:
                                              "0 1px 2px rgba(0,0,0,0.1)",
                                          }}
                                        >
                                          {zone.name}
                                        </span>
                                      ))
                                    ) : (
                                      <span
                                        style={{
                                          color: "#b0b0b0",
                                          fontStyle: "italic",
                                          fontSize: "10px",
                                        }}
                                      >
                                        No Zones
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))
                            )
                          ) : (
                            <span
                              style={{
                                color: "#ff4d4f",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              N/A
                            </span>
                          )}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <span
                              style={{
                                color: "#888",
                              }}
                            >
                              {item.venue_color_code || "N/A"}
                            </span>
                            <div
                              style={{
                                backgroundColor:
                                  item?.venue_color_code || "#ffffff",
                                height: "24px",
                                width: "50px",
                                borderRadius: "10%",
                                border: "2px solid #ddd",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              }}
                              title={`Color: ${
                                item.venue_color_code || "Default"
                              }`}
                            ></div>
                          </div>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleEditPermission(item)}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Permissions Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>

      <CreateModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
      />

      <EditModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        editData={editData}
      />
    </div>
  );
};

export default PermissionList;
