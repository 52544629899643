import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import jharkhandlogo1 from "../../../images/jharkhandlogo1.png";
import jharkhandlogo2 from "../../../images/jharkhandlogo2.png";
import jharkhandlogo3 from "../../../images/jharkhand logo3.png";
import "./style.css";
import UKLOGO from "../../../images/uk_logo.png"
export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}
const NavHader = () => {
  const loginDetails = localStorage.getItem("login_Details");
  const loginFullDetails = JSON.parse(loginDetails);
  console.log("loginDetails", loginFullDetails?.user?.name);
  const [toggle, setToggle] = useState(false);
  console.log("toggle===>", toggle);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  return (
    <div className="nav-header" style={{ zIndex: 11 }}>
      <Link to="/dashboard" className=" target-div">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img
              style={{ objectFit: "contain" }}
              height={55}
              width={55}
              src={UKLOGO}
              alt="logi"
            />
            <div className="brand-title">
              <h2 className="">UKNG</h2>
              <span className="brand-sub-title">Admin Dashboard</span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <img
              style={{ objectFit: "contain" }}
              height={55}
              width={55}
              src={UKLOGO}
              alt="logi"
            />
            <div className="brand-title">
              {" "}
              <h2 className="">UKNG</h2>
              <span className="brand-sub-title"> Admin Dashboard</span>
            </div>
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
