import React, { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axiosInstance from "../../../services/AxiosInstance";
export const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const [permissionList, setPermissionList] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [sports, setSports] = useState([]);
  const [venues, setVenues] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const getPermissionData = async (
    userType = null,
    sport = null,
    gender = null,
    subSportId = null,
    allIds = false
  ) => {
    try {
      const params = new URLSearchParams();
      if (userType) params.append("userType", userType);
      if (sport) params.append("sportsNameId", sport);
      if (gender) params.append("gender", gender);
      if (subSportId) params.append("subSportId", subSportId);  
      if(allIds) params.append('allIds', true)
      params.append("limit", limit);
      params.append("page", currentPage);
      const response = await axiosInstance.get(
        `/v2/permission?${params.toString()}`
      );
      setPermissionList(response.data);
      setTotalPages(response.data.totalPages);
      return response.data;
    } catch (error) {
      console.error("Error fetching permission data:", error);
    }
  };

  const fetchUserTypes = async () => {
    try {
      const response = await axiosInstance.get("/user-sub-type/all");
      setUserTypes(response.data.data || []);
    } catch (error) {
      console.error("Error fetching user types:", error);
      Swal.fire(
        "Error",
        "Unable to fetch User Types. Please try again later.",
        "error"
      );
    }
  };

  const fetchSports = async () => {
    try {
      const response = await axiosInstance.get("/sports-name");
      setSports(response.data.data || []);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  const fetchVenues = async () => {
    try {
      const res = await axiosInstance.get("/venue");
      const venueOptions = res.data.data.map((venue) => ({
        value: venue.id,
        label: venue.name,
      }));
      setVenues(venueOptions);
    } catch (error) {
      console.error("Error fetching venues:", error);
    }
  };

  useEffect(() => {
    getPermissionData();
    fetchUserTypes();
    fetchSports();
    fetchVenues();
  }, [currentPage, limit]);

  return (
    <PermissionContext.Provider
      value={{
        permissionList,
        setPermissionList,
        getPermissionData,
        userTypes,
        sports,
        venues,
        totalPages,
        setCurrentPage,
        currentPage,
        limit,
        setLimit,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
