import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import axiosInstance from "../../../../src/services/AxiosInstance"; // Correct axiosInstance import
import swal from "sweetalert";
import { PermissionContext } from "./PermissionContext";
import GroupMultiSelect from "./GroupMultiSelect";

const EditModal = ({ show, handleClose, editData }) => {
  const [formData, setFormData] = useState({
    userType: "",
    gender: "",
    sport: "",
    venue: "",
    zones: [],
    venueColorCode: "#000000",
    categoryCode: "",
    status: "0",
    sub_sport: null,
  });
  const { userTypes, sports, venues, getPermissionData } =
    useContext(PermissionContext);
  const [errors, setErrors] = useState({});
  const [infinitySelected, setInfinitySelected] = useState(false);
  const [allVenuesAndZones, setAllVenuesAndZones] = useState({});
  const [selectedVenueAndZones, setSelectedVenueAndZones] = useState(
    editData?.venues?.map((venue) => ({
      venueId: venue.venue_id,
      Zones: venue.zones.map((zone) => zone.id),
    })) || []
  );

  useEffect(() => {
    if (editData) {
      setFormData({
        userType: editData.user_category_sub_type?.sub_category_id || "",
        gender: editData.gender || "",
        sport: editData.ref_sports_name?.sport_id || "",
        venueColorCode: editData.venue_color_code || "#000000",
        categoryCode: editData.category_code || "",
        status: editData.status || "0",
        sub_sport: editData?.sub_sport_category_id?.sub_sport_category_id || "",
      });
      const mappedSelectedVenueAndZones =
        editData?.venues?.map((venue) => {
          return {
            venueId: venue.venue_id,
            Zones: venue.zones.map((zone) => zone.id),
          };
        }) || [];

      setSelectedVenueAndZones(mappedSelectedVenueAndZones);
    }
  }, [editData]);

  useEffect(() => {
    const getAllVenuesAndZones = async () => {
      const result = await axiosInstance.get("/venue/getAllVenuesAndZones");
      setAllVenuesAndZones(result.data?.data);
    };
    getAllVenuesAndZones();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Validate categoryCode
      if (!formData.categoryCode.replace(/\s+/g, "").length) {
        return swal("Error", "Category Code cannot be empty!", "error");
      }
  
      const updatedData = {
        selectedVenueAndZones,
        sportsNameId: formData.sport,
        gender: formData.gender,
        userType: formData.userType,
        categoryCode: formData.categoryCode.replace(/\s+/g, ""),
        venueColorCode: formData.venueColorCode,
        status: formData.status,
        isInfinite: infinitySelected,
        sub_sport: formData.sub_sport || null,
      };
  
      const response = await axiosInstance.put(
        `/v2/permission/${editData.id}`,
        updatedData
      );
  
      if (response.status === 200) {
        swal("Success", "Permission updated successfully!", "success");
        handleClose();
        getPermissionData();
      } else {
        const errorMessage =
          response.data?.message ||
          "Failed to update permission. Please try again later.";
        swal("Error", errorMessage, "error");
      }
    } catch (error) {
      console.error("Error updating permission:", error);
  
      const errorMessage =
        error?.response?.data?.message ||
        "Failed to update permission. Please try again later.";
      swal("Error", errorMessage, "error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit Permission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  User Type <span className="text-danger">*</span>
                </Form.Label>
                <select
                  className="form-control"
                  name="userType"
                  // onChange={handleChange}
                  value={formData.userType}
                  disabled 
                >
                  <option value="">Select User Type</option>
                  {userTypes.map((type) => (
                    <option
                      key={type.sub_category_id}
                      value={type.sub_category_id}
                    >
                      {type.sub_category_name_view}
                    </option>
                  ))}
                </select>
                {errors.userType && (
                  <Alert variant="danger">{errors.userType}</Alert>
                )}
              </Form.Group>
            </Col>
  
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Gender <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  // onChange={handleChange}
                  disabled 
                >
                  <option value="">Select Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Both</option>
                </Form.Control>
                {errors.gender && (
                  <Alert variant="danger">{errors.gender}</Alert>
                )}
              </Form.Group>
            </Col>
          </Row>
  
          <Row className="mb-12">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Select Sport <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="sport"
                  value={formData.sport}
                  // onChange={handleChange}
                  disabled 
                >
                  <option value="">Select Sport</option>
                  {sports?.map((type) => (
                    <option key={type.sport_id} value={type.sport_id}>
                      {type.sport_name}
                    </option>
                  ))}
                </Form.Control>
                {errors.sport && <Alert variant="danger">{errors.sport}</Alert>}
              </Form.Group>
            </Col>
  
            <Col md={6}>
              {formData.sport &&
                sports.find((sport) => sport.sport_id == formData.sport)
                  ?.categories?.length > 0 && (
                  <div className="">
                    <label>Select Sub Sport</label>
                    <select
                      className="form-control"
                      name="sub_sport"
                      onChange={handleChange}
                      value={formData.sub_sport}
                    >
                      <option value="">Select All</option>
                      {sports
                        .find((sport) => sport.sport_id == formData.sport)
                        ?.categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.sportname}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
            </Col>
          </Row>
  
          <Row className="mb-12 mt-6">
            <Col md={12}>
              <Form.Group style={{ marginBottom: "15px" }}>
                <Form.Label>
                  Color Code <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="color"
                  name="venueColorCode"
                  value={formData.venueColorCode}
                  onChange={handleChange}
                  className="w-100"
                />
                {errors.venueColorCode && (
                  <Alert variant="danger">{errors.venueColorCode}</Alert>
                )}
              </Form.Group>
            </Col>
          </Row>
  
          <div className="row">
            <Form.Label>
              Select Venue & Zones <span className="text-danger">*</span>
            </Form.Label>
            <GroupMultiSelect
              allVenuesAndZones={allVenuesAndZones}
              setSelectedVenueAndZones={setSelectedVenueAndZones}
              selectedVenueAndZones={selectedVenueAndZones}
              setInfinitySelected={setInfinitySelected}
              editData={true}
            />
          </div>
  
          <Row className="mb-3">
            {/* Category Code */}
            <Col md={6}>
              <Form.Group style={{ marginBottom: "15px" }}>
                <Form.Label>
                  Category Code <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="categoryCode"
                  value={formData.categoryCode}
                  onChange={handleChange}
                  placeholder="Enter Category Code"
                />
                {errors.categoryCode && (
                  <Alert variant="danger">{errors.categoryCode}</Alert>
                )}
              </Form.Group>
            </Col>
  
            {/* Status */}
            <Col md={6}>
              <Form.Group style={{ marginBottom: "15px" }}>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value="0">Enabled</option>
                  <option value="1">Disabled</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );  
};

export default EditModal;