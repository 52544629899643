import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";
import { PermissionContext } from "./PermissionContext";
import GroupMultiSelect from "./GroupMultiSelect";

const CreateModal = ({ show, handleClose, userType, gender, sport, subSportId }) => {
  const { getPermissionData } = useContext(PermissionContext);
  const [venueColorCode, setVenueColorCode] = useState("#000000");
  const [categoryCode, setCategoryCode] = useState("");
  const [errors, setErrors] = useState({});
  const [infinitySelected, setInfinitySelected] = useState(false);
  const [allVenuesAndZones, setAllVenuesAndZones] = useState({});
  const [selectedVenueAndZones, setSelectedVenueAndZones] = useState({
    venue: [],
    zones: [],
    isInfinite: [],
  });

  useEffect(() => {
    const fetchVenuesAndZones = async () => {
      try {
        const result = await axiosInstance.get("/venue/getAllVenuesAndZones");
        setAllVenuesAndZones(result.data?.data);
      } catch (error) {
        swal("Error", "Failed to fetch venues and zones.", "error");
      }
    };

    // if (show && Object.keys(allVenuesAndZones).length === 0) {
      fetchVenuesAndZones();
    // }
  }, [show]);

  // Reset form values when modal is closed
  const resetForm = () => {
    setVenueColorCode("#000000");
    setCategoryCode("");
    setSelectedVenueAndZones({ venue: [], zones: [], isInfinite: [] });
    setInfinitySelected(false);
    setErrors({});
  };

  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!venueColorCode) validationErrors.venueColorCode = "Color code is required.";
    if (!categoryCode) validationErrors.categoryCode = "Category code is required.";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleNewSubmit = async () => {
    if (
      selectedVenueAndZones?.venue?.length <= 0 &&
      selectedVenueAndZones?.zones?.length <= 0
    ) {
      return swal({
        title: "Venue And Zones",
        text: "You need to select at least one venue and zone.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }

    if (validateForm()) {
      try {
        const postData = {
          selectedVenueAndZones,
          venueColorCode,
          sportsNameId: sport,
          categoryCode: categoryCode.replace(/\s+/g, ""),
          userType,
          gender,
          allZone: infinitySelected,
          isInfinite: infinitySelected,
          subSportId: subSportId ?? null,
        };

        await axiosInstance.post("/v2/permission", postData);
        swal("Success", "Permission created successfully!", "success");
        resetForm();
        getPermissionData();
        handleClose();
      } catch (error) {
        swal("Error", "Permission Already Exists!", "error");
      }
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} size="lg" centered backdrop="static">
      <form>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Create Permission</h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
          >
            <span></span>
          </button>
        </div>

        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="row">
                <Form.Label>
                  Select Venue & Zones <span className="text-danger">*</span>
                </Form.Label>
                <GroupMultiSelect
                  allVenuesAndZones={allVenuesAndZones}
                  setSelectedVenueAndZones={setSelectedVenueAndZones}
                  selectedVenueAndZones={selectedVenueAndZones}
                  setInfinitySelected={setInfinitySelected}
                />
              </div>

              <div className="row">
                {/* Color Code */}
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Color Code <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    type="color"
                    value={venueColorCode}
                    onChange={(e) => setVenueColorCode(e.target.value)}
                  />
                  <small className="text-muted">
                    Selected Color: <strong>{venueColorCode}</strong>
                  </small>
                  {errors.venueColorCode && (
                    <Alert variant="danger">{errors.venueColorCode}</Alert>
                  )}
                </Form.Group>

                {/* Category Code */}
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Category Code <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Category Code"
                    value={categoryCode}
                    onChange={(e) =>
                      setCategoryCode(
                        e.target.value.replace(/[^a-zA-Z0-9-_]/g, "")
                      )
                    }
                  />
                  {errors.categoryCode && (
                    <Alert variant="danger">{errors.categoryCode}</Alert>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNewSubmit}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateModal;